/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #C0311A;
  --ion-color-primary-rgb: 192,49,26;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #a92b17;
  --ion-color-primary-tint: #c64631;
  --ion-color-secondary: #9FA617;
  --ion-color-secondary-rgb: 159,166,23;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #8c9214;
  --ion-color-secondary-tint: #a9af2e;
  --ion-color-tertiary: #FBB034;
  --ion-color-tertiary-rgb: 251,176,52;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #dd9b2e;
  --ion-color-tertiary-tint: #fbb848;
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;
  --ion-color-warning-light: #fff7df;
  --ion-color-warning-light-rgb: 255, 247, 223;
  --ion-color-warning-light-contrast: #000000;
  --ion-color-warning-light-contrast-rgb: 0, 0, 0;
  --ion-color-warning-light-shade: #e0d9c4;
  --ion-color-warning-light-tint: #fff8e2;
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;
  --ion-color-danger-light: #f9d0d5;
  --ion-color-danger-light-rgb: 249, 208, 213;
  --ion-color-danger-light-contrast: #000000;
  --ion-color-danger-light-contrast-rgb: 0, 0, 0;
  --ion-color-danger-light-shade: #dbb7bb;
  --ion-color-danger-light-tint: #fad5d9;
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;
  --ion-color-transparent: transparent;
  --ion-color-transparent-rgb: transparent;
  --ion-color-transparent-contrast: transparent;
  --ion-color-transparent-contrast-rgb: transparent;
  --ion-color-transparent-shade: transparent;
  --ion-color-transparent-tint: transparent;
  --ion-color-gradient: linear-gradient(#f1f1f1, white);
  --ion-color-gradient-rgb: 241, 241, 241;
  --ion-color-gradient-contrast: #000000;
  --ion-color-gradient-contrast-rgb: 0, 0, 0;
  --ion-color-gradient-shade: #d4d4d4;
  --ion-color-gradient-tint: #f2f2f2;
  --ion-color-grey: #eeeff1;
  --ion-color-grey-rgb: 238, 239, 241;
  --ion-color-grey-contrast: #ffffff;
  --ion-color-grey-contrast-rgb: 255, 255, 255;
  --ion-color-grey-shade: #808289;
  --ion-color-grey-tint: #9d9fa6;
  /** Bin Colours **/
  --ion-color-special: #a72dfc;
  --ion-color-special-rgb: 167, 45, 252;
  --ion-color-special-contrast: #ffffff;
  --ion-color-special-contrast-rgb: 255, 255, 255;
  --ion-color-special-shade: #9328de;
  --ion-color-special-tint: #b042fc;
  --ion-color-waste: #ef3340;
  --ion-color-waste-rgb: 239, 51, 64;
  --ion-color-waste-contrast: #ffffff;
  --ion-color-waste-contrast-rgb: 255, 255, 255;
  --ion-color-waste-shade: #d22d38;
  --ion-color-waste-tint: #f14753;
  --ion-color-recycle: #fedb00;
  --ion-color-recycle-rgb: 254, 219, 0;
  --ion-color-recycle-contrast: #000000;
  --ion-color-recycle-contrast-rgb: 0, 0, 0;
  --ion-color-recycle-shade: #e0c100;
  --ion-color-recycle-tint: #fedf1a;
  --ion-color-organic: #44d62c;
  --ion-color-organic-rgb: 68, 214, 44;
  --ion-color-organic-contrast: #000000;
  --ion-color-organic-contrast-rgb: 0, 0, 0;
  --ion-color-organic-shade: #3cbc27;
  --ion-color-organic-tint: #57da41;
  --ion-color-paper: #387ef5;
  --ion-color-paper-rgb: 56, 126, 245;
  --ion-color-paper-contrast: #ffffff;
  --ion-color-paper-contrast-rgb: 255, 255, 255;
  --ion-color-paper-shade: #316fd8;
  --ion-color-paper-tint: #4c8bf6;
  --ion-color-food: #77253c;
  --ion-color-food-rgb: 119, 37, 60;
  --ion-color-food-contrast: #ffffff;
  --ion-color-food-contrast-rgb: 255, 255, 255;
  --ion-color-food-shade: #692135;
  --ion-color-food-tint: #853b50;
  --ion-color-hard_waste: #99002e;
  --ion-color-hard_waste-rgb: 153, 0, 46;
  --ion-color-hard_waste-contrast: #ffffff;
  --ion-color-hard_waste-contrast-rgb: 255, 255, 255;
  --ion-color-hard_waste-shade: #870028;
  --ion-color-hard_waste-tint: #a31a43;
  --ion-color-greenwaste: #176117;
  --ion-color-greenwaste-rgb: 23, 97, 23;
  --ion-color-greenwaste-contrast: #ffffff;
  --ion-color-greenwaste-contrast-rgb: 255, 255, 255;
  --ion-color-greenwaste-shade: #145514;
  --ion-color-greenwaste-tint: #2e712e;
  --ion-color-clean_up: #8ed8f8;
  --ion-color-clean_up-rgb: 142,216,248;
  --ion-color-clean_up-contrast: #000000;
  --ion-color-clean_up-contrast-rgb: 0,0,0;
  --ion-color-clean_up-shade: #7dbeda;
  --ion-color-clean_up-tint: #99dcf9;
  --ion-color-green-waste: #a7b737;
  --ion-color-green-waste-rgb: 167, 183, 55;
  --ion-color-green-waste-contrast: #000000;
  --ion-color-green-waste-contrast-rgb: 0, 0, 0;
  --ion-color-green-waste-shade: #93a130;
  --ion-color-green-waste-tint: #b0be4b;
  --ion-color-mixed-waste: #00557d;
  --ion-color-mixed-waste-rgb: 0, 85, 125;
  --ion-color-mixed-waste-contrast: #ffffff;
  --ion-color-mixed-waste-contrast-rgb: 255, 255, 255;
  --ion-color-mixed-waste-shade: #004b6e;
  --ion-color-mixed-waste-tint: #1a668a;
  --ion-color-recycling_drop_off: #1e217f;
  --ion-color-recycling_drop_off-rgb: 30, 33, 127;
  --ion-color-recycling_drop_off-contrast: #ffffff;
  --ion-color-recycling_drop_off-contrast-rgb: 255, 255, 255;
  --ion-color-recycling_drop_off-shade: #1a1d70;
  --ion-color-recycling_drop_off-tint: #35378c;
  /** Ionic CSS Overrides **/
  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color: var(--ion-color-primary-contrast);
}

.ion-color-warning-light {
  --ion-color-base: var(--ion-color-warning-light);
  --ion-color-base-rgb: var(--ion-color-warning-light-rgb);
  --ion-color-contrast: var(--ion-color-warning-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning-light-shade);
  --ion-color-tint: var(--ion-color-warning-light-tint);
}

.ion-color-danger-light {
  --ion-color-base: var(--ion-color-danger-light);
  --ion-color-base-rgb: var(--ion-color-danger-light-rgb);
  --ion-color-contrast: var(--ion-color-danger-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-danger-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-danger-light-shade);
  --ion-color-tint: var(--ion-color-danger-light-tint);
}

.ion-color-special {
  --ion-color-base: var(--ion-color-special);
  --ion-color-base-rgb: var(--ion-color-special-rgb);
  --ion-color-contrast: var(--ion-color-special-contrast);
  --ion-color-contrast-rgb: var(--ion-color-special-contrast-rgb);
  --ion-color-shade: var(--ion-color-special-shade);
  --ion-color-tint: var(--ion-color-special-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-light);
  --ion-color-base-rgb: var(--ion-color-light-rgb);
  --ion-color-contrast: var(--ion-color-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-shade);
  --ion-color-tint: var(--ion-color-light-tint);
}

.ion-color-medium {
  --ion-color-base: var(--ion-color-medium);
  --ion-color-base-rgb: var(--ion-color-medium-rgb);
  --ion-color-contrast: var(--ion-color-medium-contrast);
  --ion-color-contrast-rgb: var(--ion-color-medium-contrast-rgb);
  --ion-color-shade: var(--ion-color-medium-shade);
  --ion-color-tint: var(--ion-color-medium-tint);
}

.ion-color-transparent {
  --ion-color-base: var(--ion-color-transparent);
  --ion-color-base-rgb: var(--ion-color-transparent-rgb);
  --ion-color-contrast: var(--ion-color-transparent-contrast);
  --ion-color-contrast-rgb: var(--ion-color-transparent-contrast-rgb);
  --ion-color-shade: var(--ion-color-transparent-shade);
  --ion-color-tint: var(--ion-color-transparent-tint);
}

.ion-color-gradient {
  --ion-color-base: var(--ion-color-gradient);
  --ion-color-base-rgb: var(--ion-gradient-light-rgb);
  --ion-color-contrast: var(--ion-color-gradient-contrast);
  --ion-color-contrast-rgb: var(--ion-gradient-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-gradient-shade);
  --ion-color-tint: var(--ion-color-gradient-tint);
}

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
  --ion-color-base-rgb: var(--ion-color-favorite-grey);
  --ion-color-contrast: var(--ion-color-favorite-grey);
  --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-grey);
  --ion-color-shade: var(--ion-color-favorite-grey);
  --ion-color-tint: var(--ion-color-favorite-grey);
}

.ion-color-waste {
  --ion-color-base: var(--ion-color-waste);
  --ion-color-base-rgb: var(--ion-color-waste-rgb);
  --ion-color-contrast: var(--ion-color-waste-contrast);
  --ion-color-contrast-rgb: var(--ion-color-waste-contrast-rgb);
  --ion-color-shade: var(--ion-color-waste-shade);
  --ion-color-tint: var(--ion-color-waste-tint);
}

.ion-color-recycle {
  --ion-color-base: var(--ion-color-recycle);
  --ion-color-base-rgb: var(--ion-color-recycle-rgb);
  --ion-color-contrast: var(--ion-color-recycle-contrast);
  --ion-color-contrast-rgb: var(--ion-color-recycle-contrast-rgb);
  --ion-color-shade: var(--ion-color-recycle-shade);
  --ion-color-tint: var(--ion-color-recycle-tint);
}

.ion-color-organic {
  --ion-color-base: var(--ion-color-organic);
  --ion-color-base-rgb: var(--ion-color-organic-rgb);
  --ion-color-contrast: var(--ion-color-organic-contrast);
  --ion-color-contrast-rgb: var(--ion-color-organic-contrast-rgb);
  --ion-color-shade: var(--ion-color-organic-shade);
  --ion-color-tint: var(--ion-color-organic-tint);
}

.ion-color-paper {
  --ion-color-base: var(--ion-color-paper);
  --ion-color-base-rgb: var(--ion-color-paper-rgb);
  --ion-color-contrast: var(--ion-color-paper-contrast);
  --ion-color-contrast-rgb: var(--ion-color-paper-contrast-rgb);
  --ion-color-shade: var(--ion-color-paper-shade);
  --ion-color-tint: var(--ion-color-paper-tint);
}

.ion-color-food {
  --ion-color-base: var(--ion-color-food);
  --ion-color-base-rgb: var(--ion-color-food-rgb);
  --ion-color-contrast: var(--ion-color-food-contrast);
  --ion-color-contrast-rgb: var(--ion-color-food-contrast-rgb);
  --ion-color-shade: var(--ion-color-food-shade);
  --ion-color-tint: var(--ion-color-food-tint);
}

.ion-color-hard_waste {
  --ion-color-base: var(--ion-color-hard_waste);
  --ion-color-base-rgb: var(--ion-color-hard_waste-rgb);
  --ion-color-contrast: var(--ion-color-hard_waste-contrast);
  --ion-color-contrast-rgb: var(--ion-color-hard_waste-contrast-rgb);
  --ion-color-shade: var(--ion-color-hard_waste-shade);
  --ion-color-tint: var(--ion-color-hard_waste-tint);
}

.ion-color-greenwaste {
  --ion-color-base: var(--ion-color-greenwaste);
  --ion-color-base-rgb: var(--ion-color-greenwaste-rgb);
  --ion-color-contrast: var(--ion-color-greenwaste-contrast);
  --ion-color-contrast-rgb: var(--ion-color-greenwaste-contrast-rgb);
  --ion-color-shade: var(--ion-color-greenwaste-shade);
  --ion-color-tint: var(--ion-color-greenwaste-tint);
}

.ion-color-clean_up {
  --ion-color-base: var(--ion-color-clean_up);
  --ion-color-base-rgb: var(--ion-color-clean_up-rgb);
  --ion-color-contrast: var(--ion-color-clean_up-contrast);
  --ion-color-contrast-rgb: var(--ion-color-clean_up-contrast-rgb);
  --ion-color-shade: var(--ion-color-clean_up-shade);
  --ion-color-tint: var(--ion-color-clean_up-tint);
}

.ion-color-green-waste {
  --ion-color-base: var(--ion-color-green-waste);
  --ion-color-base-rgb: var(--ion-color-green-waste-rgb);
  --ion-color-contrast: var(--ion-color-green-waste-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-waste-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-waste-shade);
  --ion-color-tint: var(--ion-color-green-waste-tint);
}

.ion-color-mixed-waste {
  --ion-color-base: var(--ion-color-mixed-waste);
  --ion-color-base-rgb: var(--ion-color-mixed-waste-rgb);
  --ion-color-contrast: var(--ion-color-mixed-waste-contrast);
  --ion-color-contrast-rgb: var(--ion-color-mixed-waste-contrast-rgb);
  --ion-color-shade: var(--ion-color-mixed-waste-shade);
  --ion-color-tint: var(--ion-color-mixed-waste-tint);
}

.ion-color-waste_drop_off {
  --ion-color-base: var(--ion-color-recycling_drop_off);
  --ion-color-base-rgb: var(--ion-color-recycling_drop_off-rgb);
  --ion-color-contrast: var(--ion-color-recycling_drop_off-contrast);
  --ion-color-contrast-rgb: var(--ion-color-recycling_drop_off-contrast-rgb);
  --ion-color-shade: var(--ion-color-recycling_drop_off-shade);
  --ion-color-tint: var(--ion-color-recycling_drop_off-tint);
}

.ion-color-near_me_map {
  --ion-color-base: var(--ion-color-primary);
  --ion-color-base-rgb: var(--ion-color-primary-rgb);
  --ion-color-contrast: var(--ion-color-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-shade);
  --ion-color-tint: var(--ion-color-primary-tint);
}

.ion-color-recycling_drop_off {
  --ion-color-base: var(--ion-color-recycling_drop_off);
  --ion-color-base-rgb: var(--ion-color-recycling_drop_off-rgb);
  --ion-color-contrast: var(--ion-color-recycling_drop_off-contrast);
  --ion-color-contrast-rgb: var(--ion-color-recycling_drop_off-contrast-rgb);
  --ion-color-shade: var(--ion-color-recycling_drop_off-shade);
  --ion-color-tint: var(--ion-color-recycling_drop_off-tint);
}